const firebaseConfig = {
  apiKey: "AIzaSyBGfyGlZnfbtaBOs3JpVBQcOEdq8tnhB6g",
  authDomain: "giftdraw-604c4.firebaseapp.com",
  projectId: "giftdraw-604c4",
  storageBucket: "giftdraw-604c4.appspot.com",
  messagingSenderId: "377123979791",
  appId: "1:377123979791:web:f7058eded100fa7553197a",
};

export default firebaseConfig;
